import store from '../../store';

import {
  setABTesting,
  setABExperiment,
  setABConfig,
} from './actions';

import { randomAB, randomFromArray } from '../../helpers/utils';
// eslint-disable-next-line import/prefer-default-export
export const initializeABTest = () => {
  const {
    AB,
    ABExp,
    ABConfig = {},
    event: { payment, slugDate },
  } = store.getState();

  const shouldUpdateABPath = !ABConfig || ABConfig.slugDate !== slugDate;

  if (shouldUpdateABPath) {
    store.middleware(store, setABConfig, { slugDate });
  }

  if (!ABExp || shouldUpdateABPath) {
    const experiment = payment.enabled ? slugDate : null;
    store.middleware(store, setABExperiment, experiment);
  }

  if (!AB || shouldUpdateABPath) {
    let priceArray = [];

    if (payment.priceList) {
      priceArray = payment.priceList.split(',');
    }

    const userPath = payment.enabled && priceArray.length
      ? randomFromArray(priceArray) : randomAB();

    store.middleware(store, setABTesting, userPath);
  }
};
