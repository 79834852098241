const socketApiPromise = import(/* webpackPrefetch: true */'../../socket');

export class NetworkError extends Error {}

const request = (eventName, ...args) => new Promise(async (resolve, reject) => {
  let timeoutReached;

  const timeout = setTimeout(() => {
    reject(new NetworkError('WS request timeout reached'));
    timeoutReached = true;
  }, 15000);

  try {
    const { socket } = await socketApiPromise;
    socket.emit(eventName, ...args, (...resolveArgs) => {
      if (!timeoutReached) {
        resolve(...resolveArgs);
        clearTimeout(timeout);
      }
    });
  } catch (err) {
    reject(err);
  }
});

export const fireAndForget = (event, ...args) => socketApiPromise
  .then(({ socket }) => socket.emit(event, ...args))
  .catch(console.error);

export const pushToQueue = (userId, sessionId, srs, inboundSource) => {
  const user = { userId, sessionId, inboundSource };
  return request('pushUserToQueue', user, srs);
};

export const takeSnapshot = (userData, cameraData) => request('takeSnapshot', userData, cameraData);
export const getQueue = (camera, joinRoom) => request('getQueue', camera, joinRoom);
export const getIsLive = userId => request('isLive', userId);
export const saveOverlay = (snapshotId, overlayKey) => request('selectOverlay', snapshotId, overlayKey);

export const getBoxes = () => request('getBoxes');
export const getSuites = () => request('getSuites');
export const getSections = (sectionType = 'stand') => request('getSections', sectionType);
export const getCustomSections = () => request('getCustomSections');
export const getRows = (sectionType = 'stand', section) => request('getRows', sectionType === 'stand' ? 'section' : sectionType, section);
export const getSeats = (sectionType = 'stand', section, row) => request('getSeats', sectionType === 'stand' ? 'section' : sectionType, section, row);

export const sendPanZoomMovement = (userId, posX, posY, scale, camera) => fireAndForget('savePanZoomSelection', {
  userId,
  posX,
  posY,
  scale,
  camera,
});

export const popUserFromQueue = async userId => fireAndForget('popUserFromQueue', userId);

export const leaveLiveStream = camera => new Promise(async (resolve, reject) => {
  try {
    const { socket, isInLiveStream, setInLiveStream } = await socketApiPromise;

    if (isInLiveStream()) {
      socket.emit('leave livestream', camera, resolve);
      setInLiveStream(false);
    } else {
      resolve();
    }
  } catch (err) {
    reject(err);
  }
});

export const joinLiveStream = (camera, force) => new Promise(async (resolve, reject) => {
  try {
    const { socket, isInLiveStream, setInLiveStream } = await socketApiPromise;

    if (!isInLiveStream() || force) {
      socket.emit('join livestream', camera, resolve);
      setInLiveStream(true);
    } else {
      resolve();
    }
  } catch (err) {
    reject(err);
  }
});

export const addEventListener = (eventName, fn) => new Promise(async (resolve, reject) => {
  try {
    const { socket } = await socketApiPromise;
    // socket.on is the same as socket.addEventListener
    socket.addEventListener(eventName, fn);
    resolve();
  } catch (err) {
    reject(err);
  }
});

export const removeListener = (eventName, fn) => new Promise(async (resolve, reject) => {
  try {
    const { socket } = await socketApiPromise;
    socket.removeListener(eventName, fn);
    resolve();
  } catch (err) {
    reject(err);
  }
});
