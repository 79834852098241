// eslint-disable-next-line import/prefer-default-export
export const defaultPanAndZoomData = {
  posX: 0.5,
  posY: 0.5,
  scale: Number.parseFloat(process.env.DEFAULT_SCALE) || 1.5,
  deltaX: 0,
  deltaY: 0,
  currentDeltaX: 0,
  currentDeltaY: 0,
};
