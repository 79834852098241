import { defaultPanAndZoomData } from '../../camera/cameraStream/constants';

export const setTempSrs = ({ tempSRS, skipSRS }, updatedSRS, updatedSkipSRS) => ({
  tempSRS: { ...tempSRS, ...updatedSRS },
  skipSRS: { ...skipSRS, ...updatedSkipSRS },
});

export const unsetTempSrs = () => ({ tempSRS: {} });

export const setSrs = ({ skipSRS }, srs, updatedSkipSRS) => ({
  srs: { ...srs },
  skipSRS: { ...skipSRS, ...updatedSkipSRS },
  panAndZoomData: defaultPanAndZoomData,
});

export const setSRSTicketIntegration = ({ srs }, ticketData) => {
  const { srsInfo, ticketId } = ticketData;
  return {
    srs: { ...srs, ...srsInfo },
    isTicketMode: true,
    sessionId: ticketId,
  };
};
