import { combineActions } from 'redux-zero/utils';
import io from 'socket.io-client';

import store from './store';
import {
  setQueue,
  setIsLive,
  setNumOfSnapshots,
  setDefaultScale,
} from './components/app/actions';

import { getWSUri } from './helpers/apis';
import { setTimes } from './helpers/sessionTime';
import { createLatencyFn } from './helpers/latency';
import { fetchEventAndSetState } from './components/app/eventState';

export const socket = io(getWSUri());

// ping/pong is set up on the server to run every 5s
// we are getting average value on every 6th run (30s)
const onNewLatencyValue = createLatencyFn(6);

const addListeners = () => {
  socket.on('connect', () => {
    const { userId } = store.getState();

    socket.emit('set id', userId, (responseUserId) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Socket ID set', responseUserId);
      }
    });

    socket.emit('getNumOfSnapshots', (numOfSnapshots) => {
      store.middleware(store, setNumOfSnapshots, numOfSnapshots);
    });

    socket.emit('getSessionTime', setTimes);

    socket.emit('getDefaultScale', ({ defaultScale }) => {
      store.middleware(store, setDefaultScale, defaultScale);
    });
  });

  socket.on('eventUpdated', () => {
    fetchEventAndSetState();
  });

  socket.on('queue changed', (queue) => {
    const { userId, isLive } = store.getState();

    if (queue[0] !== userId) {
      // updating liveSessionDuration to disable redirect on camera page
      store.middleware(store, combineActions(setQueue, setIsLive), {
        queue,
        isLive,
        liveSessionDuration: undefined,
      });
    } else {
      store.middleware(store, setQueue, { queue });
    }
  });

  socket.on('isLive', ({ live: isLive, duration = null }) => {
    store.middleware(store, setIsLive, { isLive, duration });
  });

  socket.on('pong', (latencyMs) => {
    const avgLatencyMs = onNewLatencyValue(latencyMs);

    if (avgLatencyMs) {
      const { userId, event: { slugDate } } = store.getState();
      socket.emit('userLatency', userId, slugDate, avgLatencyMs);
    }
  });
};

export const connectSocket = () => {
  if (!socket.connected) {
    socket.open();
    addListeners();
  }
};

let inLiveStream = false;

export const isInLiveStream = () => inLiveStream;
export const setInLiveStream = (val) => {
  inLiveStream = val;
};

export const getConnectionStatus = () => socket.connected;
export const disconnectSocket = () => (!socket.connected ? socket.disconnected : socket.close());
