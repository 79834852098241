import { isNullOrUndefined, isNotBoolean } from './types';
import { isDev } from './utils';
import store from '../store';
import { isPreviewMode } from './preview';

const ReactGA = import(/* webpackChunkName: "react-ga" */ 'react-ga');

export const gaGoals = Object.freeze({
  LIVE: 'liveEvent',
  TERMS: 'termsAccepted',
  GEOLOCATION: 'geolocationEnabled',
  SRS: 'srsInputted',
  SNAPSHOT: 'takeSnapshot',
  OVERLAY: 'overlaySelected',
  SHARED: 'snapshotShared',
  DEBUG: 'debug',
});

export const gaOptions = {
  siteSpeedSampleRate: 100,
};

export const initializeGA = async (isTestMode = false) => {
  if (isPreviewMode()) {
    return;
  }

  if (isNotBoolean(isTestMode)) {
    throw new Error('Wrong parameter type');
  }

  (await ReactGA).initialize(process.env.GA_APPID, {
    debug: isDev(),
    testMode: isTestMode,
    gaOptions,
  });
};

export const logGAPageView = async (path) => {
  if (isPreviewMode()) {
    return;
  }

  (await ReactGA).pageview(path);
};

// NOTE: this is unused, but it's ok to keep it in case we decide to use it
// size of the bundle does not change a lot when removed - less than 1kb uncompressed
export const logGAEvent = async (category, action, label = null, value = null) => {
  if (isPreviewMode()) {
    return;
  }

  if ([category, action].some(isNullOrUndefined)) {
    throw new Error('Missing required parameters');
  }

  (await ReactGA).event({
    category,
    action,
    label,
    value,
  });
};

export const logGAGoal = async (action, value = 0) => {
  if (isPreviewMode()) {
    return;
  }

  if (isNullOrUndefined(action)) {
    throw new Error('Missing required parameters');
  }

  if (!Object.values(gaGoals).includes(action)) {
    throw new Error('Invalid parameters');
  }

  const { userId, event: { slugDate } } = store.getState();

  (await ReactGA).event({
    category: slugDate,
    action,
    label: userId,
    value,
  });
};

export const startGASession = async (userId) => {
  if (isPreviewMode()) {
    return;
  }

  if (isNullOrUndefined(userId)) {
    throw new Error('Missing required parameters');
  }

  (await ReactGA).set(
    {
      userId,
      anonymizeIp: true,
      forceSSL: true,
      appName: process.env.BRIZI_APP_SLUG,
    },
  );
};
