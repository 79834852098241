import { h } from 'preact';
import classNames from 'classnames';

import style from './style';

const RadioIcon = ({ active }) => (
  <div className={classNames(style.radio, { [style.active]: active })} />
);

export default RadioIcon;
