import store from '../store';
import { setSRSTicketIntegration } from '../routes/findme/srs/actions';
import { checkSRS } from './apis';

const mobileTicketParams = ['ticketId', 'section', 'row', 'seat'];

const hasTicketInfo = () => {
  const url = new URL(window.location.href);
  const isTicketMode = mobileTicketParams.every(key => url.searchParams.has(key));

  return isTicketMode;
};

const getTicketInfo = () => {
  const ticketInfo = {};
  const url = new URL(window.location.href);
  mobileTicketParams.forEach((key) => {
    ticketInfo[key] = url.searchParams.get(key);
  });

  return ticketInfo;
};

// eslint-disable-next-line import/prefer-default-export
export const checkTicketInfo = () => {
  if (hasTicketInfo()) {
    const { ticketId, ...srs } = getTicketInfo();

    checkSRS(srs).then((response) => {
      const { isValid, seatType } = response;
      if (isValid && seatType) {
        store.middleware(
          store,
          setSRSTicketIntegration,
          { srsInfo: { ...srs, type: seatType }, ticketId },
        );
      }
    }).catch((err) => {
      console.error(err);
    });
  }
};
