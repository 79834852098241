// eslint-disable-next-line import/prefer-default-export
export const defaultTheme = {
  activeColor: '#042C89',
  activeTextColor: '#ffffff',
  backgroundColor: '#ffffff',
  mainTextColor: '#000000',
  primaryColor: '#6F7271',
  primaryTextColor: '#ffffff',
  secondaryColor: '#F2F2F3',
  secondaryTextColor: '#616165',
  premiumTextColor: '#FFFFFF',
  premiumGradientColorStart: '#FF641A',
  premiumGradientColorEnd: '#FF9D38',
  downloadButtonColor: '#747d8c',
  emailButtonColor: '#747d8c',
  dangerColor: '#d93636',
  successColor: '#1e854c',
};
