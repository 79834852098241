import { h } from 'preact';
import classNames from 'classnames';

import TextComponent from '../textComponents';
import Intl from '../intl';

import style from './style';

const Modal = ({
  children,
  footer = '',
  header = '',
  isActive,
  bodyRef,
  headerShadow = false,
  onClose,
}) => (
  <div
    className={classNames(style.modal, { [style.active]: isActive })}
  >
    <div className={style.background} />
    <div className={style.modalCard}>
      <header
        className={classNames({
          [style.headerShadow]: headerShadow,
          [style.header]: !headerShadow,
        })}
      >
        <TextComponent element="h2">
          {header}
        </TextComponent>
        {onClose && (
          <button type="button" aria-label="close" className={style.close} onClick={onClose}>
            <Intl textKey="I18N_CLOSE_BUTTON">Close</Intl>
            <span className={style.closeButton} />
          </button>
        )}
      </header>
      <section ref={bodyRef} className={style.body}>
        {children}
      </section>
      <footer className={style.footer}>
        <span>
          {footer}
        </span>
      </footer>
    </div>
  </div>
);

export default Modal;
