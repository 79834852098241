import { combineActions } from 'redux-zero/utils';

import store from '../../store';
import {
  updateEvent,
  checkLanguageSelection,
  updateTheme,
  updateSRSLayout,
} from './actions';

import { logError } from '../../helpers/apis/logger';
import { fetchEvent } from '../../helpers/apis';
import { initializeABTest } from './abTestHelpers';

const updateEventChain = combineActions(
  updateEvent,
  checkLanguageSelection,
  updateTheme,
  updateSRSLayout,
);

// eslint-disable-next-line import/prefer-default-export
export const fetchEventAndSetState = async () => {
  try {
    store.middleware(store, updateEvent, { error: false, loading: true });
    const event = await fetchEvent();
    store.middleware(store, updateEventChain, {
      ...event,
      error: false,
      // we are responding with event data from cache, but without "eventStatus"
      // check service-worker.js to see this logic
      // "eventStatus" property is updated when we have the server response
      // code for that update is in src/components/app/swHelpers.js
      loading: !event.eventStatus,
    });

    initializeABTest();
  } catch (err) {
    logError({ message: 'Error fetching event', errMessage: err.message });
    store.middleware(store, updateEvent, { error: true, loading: false });
    console.error(err);
  }
};
