import { h } from 'preact';
import classNames from 'classnames';
import './style';

const FaIcon = ({ icon: { paths, viewBox }, size = 'md' }) => (
  <svg
    aria-hidden="true"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox.join(' ')}
    focusable="false"
    className={classNames('svg-inline--fa', `fa-${size}`)}
  >
    {paths.map(({ d, class: className }) => (
      <path fill="currentColor" className={className} d={d} />
    ))}
  </svg>
);

export default FaIcon;
