import { isNull } from '../helpers/types';

// TODO: consider moving tempSRS session storage
export const persistedKeys = [
  'userId',
  'srs',
  'intl',
  'termsAccepted',
  'camera',
  'numOfSnapshots',
  'boxValue',
  'inboundSource',
  'tempSRS',
  'skipSRS',
  'theme',
  'rating',
  'hasUserShared',
  'geolocationEnabled',
  'onboardingDisplayed',
  'AB',
  'ABExp',
  'ABConfig',
  'isUserBanned',
  'srsLayout',
];

// check if value with key from `persistedKeys` is changed and store it in localStorage
export const persistMiddleware = store => next => (action) => {
  const prevState = store.getState() || {};
  const result = next(action);
  const nextState = store.getState();

  if (window.localStorage) {
    persistedKeys.forEach((key) => {
      if (nextState[key] === undefined) {
        localStorage.removeItem(key);
      } else if (prevState[key] !== nextState[key]) {
        localStorage.setItem(key, JSON.stringify(nextState[key]));
      }
    });
  }

  return result;
};

export const getPersistedState = () => {
  const persistedState = {};

  if (typeof window === 'undefined' || !window.localStorage) {
    return persistedState;
  }

  persistedKeys.forEach((key) => {
    const item = localStorage.getItem(key);

    if (!isNull(item)) {
      try {
        persistedState[key] = JSON.parse(item);
      } catch (err) {
        if (err instanceof SyntaxError) {
          persistedState[key] = item;
        } else {
          console.error(err);
        }
      }
    }
  });

  return persistedState;
};
