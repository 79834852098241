import { h } from 'preact';
import { connect } from 'redux-zero/preact';
import { useState, useEffect } from 'preact/hooks';
import classNames from 'classnames';
import { logError } from '../../../helpers/apis/logger';

import style from './style';

const getSVG = async (URL) => {
  try {
    const response = await fetch(URL);

    if (!response.ok) {
      throw new Error(`fetching SVG image, status: ${response.status}, URL: ${URL}`);
    }

    return response.text();
  } catch (err) {
    logError({ message: err.toString() });
    console.error(err);
    return null;
  }
};

const replacer = colors => (match) => {
  switch (match) {
  case '#032C89':
    return colors.stadiumSeats;
  case '#E9EFF3':
    return colors.stadiumWalls;
  case '#F2F6F8':
    return colors.stadiumDoors;
  case '#FF5250':
    return colors.stadiumFlags;
  case '#8C83E4':
    return colors.cameraLens;
  case '#3E94FF':
    return colors.mapGrid;
  case '#2196F3':
    return colors.successColor;
  case '#FF5251':
    return colors.dangerColor;
  case '#FF5252':
    return colors.standSeats;
  case '#0F8FF1':
    return colors.boxSeats;
  case '#8C83E5':
    return colors.suiteSeats;
  case '#FFFFFF':
    return colors.cloudBackground;
  default:
    return match;
  }
};

const replaceColors = (str, colors) => {
  // only these UPPERCASE colors will be replaced because the same color might be used in
  // multiple places but only some of them need to be replaced ex: #ffffff and #FFFFFF.
  const regExp = /#032C89|#E9EFF3|#F2F6F8|#FF5250|#8C83E4|#3E94FF|#2196F3|#FF5251|#FF5252|#0F8FF1|#8C83E5|#FFFFFF/g;
  return str.replace(regExp, replacer(colors));
};

const SVG = (props) => {
  const {
    src,
    onLoad = () => {},
    iconColor = {},
    theme: { dangerColor, successColor } = {},
    pageImage,
    className: customClass,
  } = props;

  const [text, setText] = useState();
  const colors = { ...iconColor, dangerColor, successColor };

  useEffect(() => {
    getSVG(src).then(setText);
  }, [src]);

  useEffect(() => {
    if (text) {
      onLoad();
    }
  }, [text]);

  if (!text) {
    return (
      <div className={classNames(customClass, { [style.pageImage]: pageImage })}>
        <div />
      </div>
    );
  }

  return (
    <div
      className={classNames(customClass, { [style.pageImage]: pageImage })}
      // the key property is important for react to keep track of this element
      // if not added there will be rendering issue when the event is updated
      key={src}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: replaceColors(text, colors) }}
    />
  );
};

export default connect(({ event: { iconColor, theme } }) => ({ iconColor, theme }))(SVG);
